<template>
  <div class="container2" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <div v-if="page === 1">
      <el-select style="margin-right: 10px;"
        v-model="client"
        placeholder="請選擇品牌"
        @change="getLottery">
        <el-option v-for="item in clientList"
          :key="item.clientId"
          :label="item.name"
          :value="item.clientId">
        </el-option>
      </el-select>
      <div v-if="client" style="text-align: right; margin-top: 10px;">
        <el-table :data="lotteryLists"
          :header-cell-style="tableHeaderColor"
          empty-text="暫無資料"
          style="width: 100%"
          height="calc(100vh - 230px)">
          <el-table-column prop="title"
            label="活動名稱">
          </el-table-column>
          <el-table-column prop="lotteryId"
            label="活動編號">
          </el-table-column>
          <el-table-column prop="onShelf"
            label="上架時間">
            <template slot-scope="scope">
              <div>{{ getTimeFormat(scope.row.onShelf) }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="offShelf"
            label="下架時間">
            <template slot-scope="scope">
              <div>{{ getTimeFormat(scope.row.offShelf) }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="edit"
            width="150"
            label="操作">
            <template slot-scope="scope">
              <el-button size="small"
                style="margin-bottom: 0 !important;"
                type="primary"
                @click="editLottery(scope.row)">
                編輯
              </el-button>
              <el-button size="small"
                style="margin: 0 !important;"
                type="danger"
                @click="checkDelete(scope.row.lotteryId)">
                刪除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button style="margin-top: 10px; margin-right: 0 !important;"
          type="primary"
          @click="addLottery">
          新增活動
        </el-button>
      </div>
      <mu-dialog width="360" :open.sync="deleteDia">
        確定要刪除此活動嗎？
        <mu-button slot="actions" flat color="primary" @click="deleteDia = false">取消</mu-button>
        <mu-button slot="actions" color="primary" @click="sendDelete">確認</mu-button>
      </mu-dialog>
    </div>
    <div v-if="page === 2">
      <div style="height: calc(100vh - 200px); overflow-y: auto; overflow-x: hidden; padding-bottom: 20px;">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-input placeholder="例如：幸運輪盤好禮抽" v-model.trim="updateData.title">
              <template slot="prepend">活動名稱</template>
            </el-input>
            <!-- 活動說明連結 -->
            <div style="margin-top: 15px;">
              <el-input placeholder="例如：https://eilis-ai.com" v-model.trim="updateData.link">
                <template slot="prepend">活動說明連結</template>
              </el-input>
            </div>
            <!-- 遊戲類性 -->
            <div style="margin-top: 20px; margin-bottom: 5px; font-weight: 500; font-size: 15px;">
              <el-row :gutter="20">
                <el-col :span="6">
                  <div style="font-size: 15px; font-weight: 500;">
                    遊戲類型
                  </div>
                </el-col>
                <el-col :span="16">
                  <el-radio v-model="updateData.gameType" label="1">輪盤</el-radio>
                  <el-radio v-model="updateData.gameType" label="2">紅包</el-radio>
                </el-col>
              </el-row>
            </div>
            <!-- 所需點數 -->
            <div style="margin-top: 15px;">
              <el-row :gutter="20">
                <el-col :span="6" style="line-height: 40px;">
                  <div style="font-size: 15px; font-weight: 500;">
                    每次所需點數
                  </div>
                </el-col>
                <el-col :span="16">
                  <el-input-number v-model.number="updateData.point" :min="0"></el-input-number>
                </el-col>
              </el-row>
            </div>
            <!-- 背景圖片 -->
            <div style="margin-top: 20px;">
              <el-row :gutter="20">
                <el-col :span="6" style="line-height: 40px;">
                  <div style="font-size: 15px; font-weight: 500;">
                    背景圖片
                  </div>
                </el-col>
                <el-col :span="7">
                  <img :src="updateData.bgImg ? updateData.bgImg : defaultImg" width="100%"/>
                </el-col>
                <el-col :span="10">
                  <div style="margin-top: 7px;">
                    <label v-if="!bgImgUpload"
                      @click="nowUpload = 'bg'"
                      style="padding: 7px 20px; border-radius: 4px; cursor: pointer; font-weight: 500; background-color: #409EFF; color: #fff;">
                      <input style="display:none;" type="file" @change="onFileSelected">
                      <span>上傳圖片</span>
                    </label>
                    <mu-flex class="demo-linear-progress" v-if="bgImgUpload">
                      <mu-linear-progress></mu-linear-progress>
                    </mu-flex>
                  </div>
                  <div style="margin-top: 15px;">圖片尺寸：828 * 1792</div>
                  <div>檔案規格：PNG、JPEG</div>
                  <div>檔案上限：100 KB</div>
                  <div style="color: #f24;">{{ bgImgHint }}</div>
                </el-col>
              </el-row>
            </div>
            <!-- 背景圖片 -->
            <div style="margin-top: 10px;">
              <el-row :gutter="20">
                <el-col :span="6" style="line-height: 40px;">
                  <div style="font-size: 15px; font-weight: 500;">
                    {{ (updateData.gameType === '1') ? '指針圖片' : '紅包按鈕圖片' }}
                  </div>
                </el-col>
                <el-col :span="7">
                  <img :src="updateData.pointerImg ? updateData.pointerImg : defaultImg" width="100%"/>
                </el-col>
                <el-col :span="10">
                  <div style="margin-top: 7px;">
                    <label v-if="!pointerImgUpload"
                      @click="nowUpload = 'pointer'"
                      style="padding: 7px 20px; border-radius: 4px; cursor: pointer; font-weight: 500; background-color: #409EFF; color: #fff;">
                      <input style="display:none;" type="file" @change="onFileSelected">
                      <span>上傳圖片</span>
                    </label>
                    <mu-flex class="demo-linear-progress" v-if="pointerImgUpload">
                      <mu-linear-progress></mu-linear-progress>
                    </mu-flex>
                  </div>
                  <div style="margin-top: 15px;">圖片尺寸：100 * 100</div>
                  <div>檔案規格：PNG、JPEG</div>
                  <div>檔案上限：100 KB</div>
                  <div style="color: #f24;">{{ pointerImgHint }}</div>
                </el-col>
              </el-row>
            </div>
            <!-- 輪盤圖片 -->
            <div style="margin-top: 10px;">
              <el-row :gutter="20">
                <el-col :span="6" style="line-height: 40px;">
                  <div style="font-size: 15px; font-weight: 500;">
                    {{ (updateData.gameType === '1') ? '輪盤圖片' : '紅包圖片' }}
                  </div>
                </el-col>
                <el-col :span="7">
                  <img :src="updateData.rouletteImg ? updateData.rouletteImg : defaultImg" width="100%"/>
                </el-col>
                <el-col :span="10">
                  <div style="margin-top: 7px;">
                    <label v-if="!rouletteImgUpload"
                      @click="nowUpload = 'roulette'"
                      style="padding: 7px 20px; border-radius: 4px; cursor: pointer; font-weight: 500; background-color: #409EFF; color: #fff;">
                      <input style="display:none;" type="file" @change="onFileSelected">
                      <span>上傳圖片</span>
                    </label>
                    <mu-flex class="demo-linear-progress" v-if="rouletteImgUpload">
                      <mu-linear-progress></mu-linear-progress>
                    </mu-flex>
                  </div>
                  <div style="margin-top: 15px;">{{ (updateData.gameType === '1') ? '圖片尺寸：500 * 500' : '圖片尺寸：380 * 620' }}</div>
                  <div>檔案規格：PNG、JPEG</div>
                  <div>檔案上限：100 KB</div>
                  <div style="color: #f24;">{{ rouletteImgHint }}</div>
                </el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :span="12">
            <!-- 上下架時間 -->
            <div>
              <el-row :gutter="10">
                <el-col :span="12">
                  <div style="margin-bottom: 5px; font-weight: 500; font-size: 15px;">上架時間</div>
                  <el-date-picker style="width: 100%"
                    value-format="timestamp"
                    v-model="updateData.onShelf"
                    type="date"
                    placeholder="選擇日期">
                  </el-date-picker>
                </el-col>
                <el-col :span="12">
                  <div style="margin-bottom: 5px; font-weight: 500; font-size: 15px;">下架時間</div>
                  <el-date-picker style="width: 100%"
                    value-format="timestamp"
                    v-model="updateData.offShelf"
                    type="date"
                    placeholder="選擇日期">
                  </el-date-picker>
                </el-col>
              </el-row>
            </div>
            <!-- 次數 -->
            <div style="margin-top: 30px; margin-bottom: 20px;">
              <el-row :gutter="20">
                <el-col :span="6">
                  <div style="font-size: 15px; font-weight: 500;">
                    每日可玩次數
                  </div>
                </el-col>
                <el-col :span="16">
                  <el-radio v-model="updateData.limit" :label="1">一次</el-radio>
                  <el-radio v-model="updateData.limit" label="">無上限</el-radio>
                </el-col>
              </el-row>
            </div>
            <!-- 獎品 -->
            <el-radio-group v-model="nowIdx">
              <el-radio-button v-for="(item, index) in updateData.items"
                :key="index"
                :label="index">
                獎項{{ index + 1 }}
              </el-radio-button>
            </el-radio-group>
            <div class="giftBorder">
              <!-- 獎品類型 -->
              <div style="font-size: 15px; font-weight: 500;">
                <span style="margin-right: 20px;">獎品類型</span>
                <el-radio v-model="updateData.items[nowIdx].type" label="coupon">贈送優惠券</el-radio>
                <el-radio v-model="updateData.items[nowIdx].type" label="none">未中獎</el-radio>
              </div>
              <div v-if="updateData.items[nowIdx].type === 'coupon'">
                <!-- 獎品名稱 -->
                <div style="margin-top: 10px;">
                  <el-input placeholder="例如：最新產品兌換券" v-model.trim="updateData.items[nowIdx].name">
                    <template slot="prepend">獎品名稱</template>
                  </el-input>
                </div>
                <!-- 優惠券名稱 -->
                <div style="font-size: 15px; font-weight: 500; margin-top: 10px;">
                  <span style="margin-right: 20px;">優惠券名稱</span>
                  <mu-select v-model="updateData.items[nowIdx].itemNo"
                    @change="getCoupon">
                    <mu-option v-for="coupon in itemLists"
                      :key="coupon.itemNo"
                      :value="coupon.itemNo"
                      :label="coupon.title">
                    </mu-option>
                  </mu-select>
                </div>
                <!-- 獎品機率 -->
                <div style="font-size: 15px; font-weight: 500; margin-top: -10px;">
                  <span style="margin-right: 20px;">獎品機率</span>
                  <el-input v-model.number="updateData.items[nowIdx].percentage"
                    style="width: 100px;"
                    placeholder="例如：20">
                  </el-input> %
                </div>
                <!-- 中獎通知 -->
                <div style="font-size: 15px; font-weight: 500; margin-top: 15px;">
                  <span style="margin-right: 20px;">中獎通知</span>
                  <el-switch v-model="updateData.items[nowIdx].notification"
                    active-color="#13ce66"
                    inactive-color="#ff4949">
                  </el-switch>
                  <el-input type="textarea"
                    :rows="3"
                    style="margin-top: 10px;"
                    placeholder="例如：恭喜中獎！"
                    v-model="updateData.items[nowIdx].message">
                  </el-input>
                </div>
              </div>
              <div v-else>
                <!-- 獎品機率 -->
                <div style="font-size: 15px; font-weight: 500; margin-top: 10px;">
                  <span style="margin-right: 20px;">獎品機率</span>
                  <el-input v-model.number="updateData.items[nowIdx].percentage"
                    style="width: 100px;"
                    placeholder="例如：20">
                  </el-input> %
                </div>
              </div>
            </div>
            <div style="text-align: left; margin-top: 10px; font-weight: 500; font-size: 15px;"
              :style="{ color: calcProp.color }">
              當前機率總和：{{ calcProp.total }}/100
            </div>
          </el-col>
        </el-row>
      </div>
      <hr/>
      <div style="text-align: right;">
        <el-button type="info" @click="page = 1">返回</el-button>
        <el-button type="warning" style="margin: 0 !important;"
          :disabled="!updateData.title"
          @click="saveChange">儲存設定
        </el-button>
      </div>
      <mu-dialog width="360" :open.sync="errorDia">
        {{ errorMsg }}
      </mu-dialog>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex';
import { getFullDate } from '@/config/date';
import defaultImg from '../assets/default-image.png'

const defaultItem = {
  type: 'coupon',
  name: '',
  itemNo: '',
  itemName: '',
  percentage: 0,
  notification: true,
  message: '恭喜中獎\n中獎人姓名：ELS_NAME\n中獎序號：PRIZE_CODE'
}

// note：PRIZE_CODE = couponId

export default {
  data() {
    return {
      page: 1,
      pageLoading: false,
      enviro: 'pro',
      itemLists: [],
      lotteryLists: [],
      client: '',
      enviros: [
        { value: 'pro', name: '正式站' },
        { value: 'test', name: '測試站' }
      ],
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      types: [
        { label: '六格輪盤', value: 6 }
      ],
      nowUpload: '',
      rouletteImgUpload: false,
      bgImgUpload: false,
      pointerImgUpload: false,
      rouletteImgHint: '',
      bgImgHint: '',
      pointerImgHint: '',
      nowIdx: 0,
      defaultImg: defaultImg,
      deleteId: '',
      deleteDia: false,
      updateData: {
        gameType: '1',
        title: '',
        lotteryId: '',
        link: '',
        rouletteImg: '',
        pointerImg: '',
        bgImg: '',
        onShelf: Date.now(),
        offShelf: Date.now(),
        type: 6,
        limit: 1,
        point: 0,
        items: [defaultItem]
      },
      errorDia: false,
      errorMsg: ''
    };
  },
  mounted() {
    if (this.clientList && this.clientList.length > 0) {
      // do something
    }
    else {
      this.getBrands();
    }
  },
  computed: {
    ...mapGetters({
      clientList: 'clients'
    }),
    calcProp() {
      let obj = {
        color: '#999',
        total: 0
      }
      for (let i = 0; i < this.updateData.items.length; i += 1) {
        if (this.updateData.items[i].percentage) {
          obj.total += this.updateData.items[i].percentage;
        }
      }
      if (obj.total < 100) {
        obj.color = '#999';
      }
      else if (obj.total > 100) {
        obj.color = '#f24';
      }
      else {
        obj.color = '#13CE66';
      }
      return obj;
    }
  },
  methods: {
    ...mapActions({
      getBrands: 'getBrands',
      setLottery: 'setLottery',
      deleteLottery: 'deleteLottery',
      getLotteries: 'getLotteries',
      getItemList: 'getItemList',
      getClientData: 'getClientData'
    }),
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #eee; font-weight: 500; color: #444;'
      }
    },
    getTimeFormat(time) {
      return getFullDate(time, 'dash');
    },
    onFileSelected(event) {
      const file = event.target.files[0];
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt100k = file.size / 1024 / 1024 <= 0.1;
      // 檔案類型
      if (!isJPG) {
        if (this.nowUpload === 'roulette') { this.rouletteImgHint = '不支援此檔案類型' }
        if (this.nowUpload === 'bg') { this.bgImgHint = '不支援此檔案類型' }
        if (this.nowUpload === 'pointer') { this.pointerImgHint = '不支援此檔案類型' }
      }
      else if (!isLt100k) {
        if (this.nowUpload === 'roulette') { this.rouletteImgHint = '檔案大小不得大於 100 KB' }
        if (this.nowUpload === 'bg') { this.bgImgHint = '檔案大小不得大於 100 KB' }
        if (this.nowUpload === 'pointer') { this.pointerImgHint = '檔案大小不得大於 100 KB' }
      }
      else {
        if (this.nowUpload === 'roulette') { this.rouletteImgUpload = true; this.rouletteImgHint = '' }
        if (this.nowUpload === 'bg') { this.bgImgUpload = true; this.bgImgHint = '' }
        if (this.nowUpload === 'pointer') { this.pointerImgUpload = true; this.pointerImgHint = '' }
        const formData = new FormData();
        formData.append('myfile', file);
        const config = {
          header: {
            "content-type": "multipart/form-data"
          }
        };
        this.axios.post('https://ruentex-api-server.eilis-ai.com/api/upload', formData, config)
          .then((res) => {
            if (this.nowUpload === 'roulette') {
              this.rouletteImgUpload = false;
              this.updateData.rouletteImg = res.data.url;
            }
            if (this.nowUpload === 'bg') {
              this.bgImgUpload = false;
              this.updateData.bgImg = res.data.url;
            }
            if (this.nowUpload === 'pointer') {
              this.pointerImgUpload = false;
              this.updateData.pointerImg = res.data.url;
            }
          })
      }
    },
    editLottery(row) {
      this.updateData = JSON.parse(JSON.stringify(row));
      this.page = 2;
    },
    checkDelete(id) {
      this.deleteDia = true;
      this.deleteId = id;
    },
    sendDelete() {
      this.deleteDia = false;
      this.pageLoading = true;
      this.deleteLottery({ clientId: this.client, lotteryId: this.deleteId })
        .then(() => {
          this.getAllLottery();
        })
    },
    addLottery() {
      this.updateData.gameType = '1';
      this.updateData.title = '';
      this.updateData.lotteryId = '';
      this.updateData.link = '';
      this.updateData.rouletteImg = '';
      this.updateData.bgImg = '';
      this.updateData.pointerImg = '';
      this.updateData.onShelf = Date.now();
      this.updateData.offShelf = Date.now();
      this.updateData.type = 6;
      this.updateData.limit = 1;
      this.updateData.point = 0;
      this.updateData.items = [];
      for (let i = 0; i < 6; i += 1) {
        this.updateData.items.push(JSON.parse(JSON.stringify(defaultItem)));
      }
      this.page = 2;
    },
    getLottery() {
      this.pageLoading = true;
      this.getItemList({ clientId: this.client, enviro: this.enviro })
        .then((res) => {
          if (res.itemData) {
            this.itemLists = res.itemData.list ? res.itemData.list : [];
          }
        })
      this.getAllLottery();
    },
    getCoupon(val) {
      let itemName = '';
      let redeemPoint = 0;
      for (let i = 0; i < this.itemLists.length; i += 1) {
        if (this.itemLists[i].itemNo === val) {
          itemName = this.itemLists[i].title;
          if (this.itemLists[i].point) {
            redeemPoint = this.itemLists[i].point;
          }
        }
      }
      this.updateData.items[this.nowIdx].itemName = itemName;
      this.updateData.items[this.nowIdx].redeemPoint = redeemPoint;
    },
    getType(val) {
      this.updateData.items = [];
      for (let i = 0; i < val; i += 1) {
        this.updateData.items.push(JSON.parse(JSON.stringify(defaultItem)));
      }
    },
    getAllLottery() {
      this.getLotteries({ clientId: this.client })
        .then((res) => {
          this.lotteryLists = res.result;
          this.pageLoading = false;

          // console.log(this.lotteryLists);
        })
    },
    saveChange() {
      if (this.calcProp.total < 100) {
        this.errorDia = true;
        this.errorMsg = '當前機率總和小於 100%';
      }
      else if (this.calcProp.total > 100) {
        this.errorDia = true;
        this.errorMsg = '當前機率總和大於 100%';
      }
      else {
        this.errorDia = false;
        this.updateData.onShelf = new Date(this.updateData.onShelf).getTime();
        this.updateData.offShelf = new Date(this.updateData.offShelf).getTime();
        const postData = {
          clientId: this.client,
          lottery: this.updateData
        };
        this.pageLoading = true;
        this.setLottery(postData)
          .then(() => {
            this.page = 1;
            this.getAllLottery();
          })
      }
    }
  }
}
</script>

<style scoped>
  .typeBtn:hover {
    background-color: #f56c6c;
    color: #fff;
  }
  .container2 {
    text-align: left;
    padding: 20px;
  }
  .img {
    width: 120px;
    height: 120px;
    background-size: cover;
    border: 1px solid #eee;
  }
  .active {
    display: inline-block;
    border: 1px solid #f56c6c;
    background-color: #f56c6c;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 10px;
    padding: 6px 20px;
    transition: 0.3s;
  }
  .typeBtn {
    display: inline-block;
    border: 1px solid #f56c6c;
    color: #f56c6c;
    font-weight: 500;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 10px;
    padding: 6px 20px;
    transition: 0.3s;
  }
  .giftBorder {
    border: 1px solid #eee;
    border-radius: 15px;
    margin-top: 20px;
    padding: 20px;
    width: 90%;
  }
</style>